import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { getProduct } from "../../api/productApi"
import { SuppliesOrderContext } from "../../context/SuppliesOrderProvider"
import {
  Buttons,
  DEFAULT_ATTRIBUTE_STATE,
  LoginContext,
  PrimaryButton,
  ProductModal,
  ProductQuantityInput,
} from "social-supermarket-components"
import {
  AttributeStateType,
  getFormattedPriceRange,
  ProductType,
  VariantType,
} from "social-supermarket-model"

const ButtonsContainer = styled(Buttons)`
  align-items: center;
  justify-content: flex-end;
`

const Price = styled.div`
  margin-right: 15px;
`

const QuantityInput = styled(ProductQuantityInput)`
  width: 75px;
  height: 40px;
`

interface Props {
  productId: number
  onClose
  show
  mode?: "order" | "gift-list" | ""
  priceIncludesShipping: boolean
}

const ProductSuppliesModal: FC<Props> = ({
  productId,
  onClose,
  show,
  mode,
  priceIncludesShipping,
}) => {
  const { user } = useContext(LoginContext)
  const { addBasketItems } = useContext(SuppliesOrderContext)

  const [quantity, setQuantity] = useState<number>(1)

  const [product, setProduct] = useState<ProductType>(null)
  const [attributeState, setAttributeState] = useState<AttributeStateType>({
    ...DEFAULT_ATTRIBUTE_STATE,
  })

  useEffect(() => {
    if (productId) {
      fetchProduct()
    }
  }, [productId])

  useEffect(() => {
    if (!show) {
      setProduct(null)
    }
  }, [show])

  useEffect(() => {
    if (product) {
      setAttributeState({ ...DEFAULT_ATTRIBUTE_STATE, variants: product.variants })
    } else {
      setAttributeState({ ...DEFAULT_ATTRIBUTE_STATE })
    }
  }, [product, show])

  const handleAddToBasket = () => {
    if (product.variants) {
      const selections = inStockVariants.map(variant => ({ product, variant, quantity }))
      addBasketItems([...selections])
    } else {
      addBasketItems([{ product, variant: null, quantity }])
    }
    onClose()
  }

  const inStockVariants = getInStockSelectedVariants(attributeState.variants) || []

  const fetchProduct = async () => {
    try {
      const product = await getProduct(productId, user.ringfenced)
      setProduct(product)
    } catch (e) {
      console.log(e)
    }
  }

  const hasOneItem = product?.variants ? inStockVariants.length === 1 : true

  return (
    <ProductModal
      product={product}
      onClose={onClose}
      show={show}
      attributeState={attributeState}
      setAttributeState={setAttributeState}
    >
      {product && (
        <ButtonsContainer>
          <Price>
            {getFormattedPriceRange(
              product,
              attributeState.variants,
              quantity,
              null,
              priceIncludesShipping
            )}
          </Price>
          <QuantityInput product={product} quantity={quantity} onChange={v => setQuantity(v)} />
          <PrimaryButton slim disabled={!hasOneItem} onClick={handleAddToBasket}>
            Add To Basket
          </PrimaryButton>
        </ButtonsContainer>
      )}
    </ProductModal>
  )
}

const getInStockSelectedVariants = (variants: VariantType[]) => {
  return variants?.filter(variant => {
    return variant.stockStatus === "IN_STOCK"
  })
}

export default ProductSuppliesModal
